/**
 * Basic typography style for copy text
 */
body {
  color: theme-color(primary);
  font-family: 'Berthold Regular';
  line-height: 2;
  font-weight: 300;

  h1,h2,h3,h4,h5,h6 {
    font-family: 'Berthold Bold';
  }

  .highlight-title {
    text-transform: uppercase;
    font-size: 1rem;
    @include media-breakpoint-up(md){
      &:before {
        content: '_____________';
        margin-right: 1em;
        margin-bottom: 1em;
      }
    } 
  }

  p {
    font-weight: 300; 
  }

  strong { font-weight: 700; }

  a {
    color: darken($color: theme-color(primary), $amount: 10%);
    font-weight: 700;
    text-decoration: underline;
    &:hover, &:focus {
      color: theme-color(secondary);
    }
  }
}

html {
  font-size: 14px;
  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
}

blockquote {
  @extend .blockquote;
  font-size: 1rem;
  font-size: 300;
}
